import './css/TestStore.css';

function TestStore() {
    return(
        <div>
            TestStore
        </div>
    )
}

export default TestStore;