import Navbar from './Navbar';
import Footer from './Footer';
import './App.css';
import { Route, Routes } from 'react-router';
import Home from './Pages/Home';
import Cart from './Pages/UserPages/Cart';
import Favorite from './Pages/UserPages/Favorite';
import Contact from './Pages/Contact';
import Login from './Pages/UserPages/Login';
import Register from './Pages/UserPages/Register';
import Profile from './Pages/UserPages/Profile';
import TestProduct from './Pages/Products/TestProduct';
import TestStore from './Pages/Stores/TestStore';
import Registered from './Pages/UserPages/Registered';

function App() {
  return (
    <>
        <Navbar />
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/Cart' element={<Cart/>} />
            <Route path='/Favorite' element={<Favorite/>} />
            <Route path='/kontakt-oss' element={<Contact/>} />
            <Route path='/Login' element={<Login/>} />
            <Route path='/Register' element={<Register/>} />
            <Route path='/Profile' element={<Profile/>} />
            <Route path='/TestStore' element={<TestStore />} />
            <Route path='/TestProduct' element={<TestProduct />} />
            <Route path='/Registered' element={<Registered />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
