import './Navbar.css'
import logo from './Images/Spris_logo.png';

import { BugermenyClicked, bottomBugermenyClicked } from './JSFiles/navbar/BurgermenuClick';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faCartShopping, faHeart } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    return (
        <div className='navbar'>
            <header className='navbar-header'>
                <div className='Header-column1'></div>
                <div className='Header-Content'>
                    <Link to='/'>
                        <img src={logo} alt='logo' className='Logo' />
                    </Link>
                    <div className='search-container'>
                        <input className='search-input' placeholder='Søk...' type='text' />
                        <div className='search-icon-container'>
                            <FontAwesomeIcon icon={faSearch} color="#000000" className='search' />
                        </div>
                    </div>
                    <div className='user-pages'>
                        <Link to='/Favorite' className='user-page-link'>
                            <FontAwesomeIcon icon={faHeart} color="#fffff" className='favorite' />
                        </Link>
                        <Link to='/Cart' className='user-page-link'>
                            <FontAwesomeIcon icon={faCartShopping} color="#fffff" className='cart' />
                        </Link>
                        <Link to='/Profile' className='user-page-link'>
                            <FontAwesomeIcon icon={faUser} color="#fffff" className='user' />
                        </Link>
                    </div>
                    <div className='hamburgermenu' onClick={BugermenyClicked}>
                        <div className='hamburger-child'></div>
                        <div className='hamburger-child'></div>
                        <div className='hamburger-child'></div>
                    </div>
                </div>
                <div className='Header-column3'></div>
                <div className='hamburger-slide'>
                    <ul>
                        <li>
                            <Link to='/Profile' className='user-page-link-hamburger' onClick={BugermenyClicked}>
                                <FontAwesomeIcon icon={faUser} color="#fffff" className='user' />
                                Profil
                            </Link>
                        </li>
                        <li>
                            <Link to='/Cart' className='user-page-link-hamburger' onClick={BugermenyClicked}>
                                <FontAwesomeIcon icon={faCartShopping} color="#fffff" className='cart' />
                                Handlevogn
                            </Link>
                        </li>
                        <li>
                            <Link to='/Favorite' className='user-page-link-hamburger' onClick={BugermenyClicked}>
                                <FontAwesomeIcon icon={faHeart} color="#fffff" className='favorite' />
                                Favoritter
                            </Link>
                        </li>
                    </ul>
                </div>
            </header>
            <div className='LowerHeader'>
                <div className='bottom-hamburger-slide'>

                    <ul>
                        <li>
                            <Link to='/Profile' className='user-page-link-hamburger'>
                                <FontAwesomeIcon icon={faUser} color="#fffff" className='user hamburgericon' />
                                Profil
                            </Link>
                        </li>
                        <li>
                            <Link to='/Cart' className='user-page-link-hamburger'>
                                <FontAwesomeIcon icon={faCartShopping} color="#fffff" className='cart hamburgericon' />
                                Handlevogn
                            </Link>
                        </li>
                        <li>
                            <Link to='/Favorite' className='user-page-link-hamburger'>
                                <FontAwesomeIcon icon={faHeart} color="#fffff" className='favorite hamburgericon' />
                                Favoritter
                            </Link>
                        </li>
                    </ul>
                </div>
                <Link to='/'>
                    <img src={logo} alt='logo' className='Logo' />
                </Link>
                <div className='bottom-user-pages'>
                    <Link to='/Favorite' className='user-page-link'>
                        <FontAwesomeIcon icon={faHeart} color="#fffff" className='favorite' />
                    </Link>
                    <Link to='/Cart' className='user-page-link'>
                        <FontAwesomeIcon icon={faCartShopping} color="#fffff" className='cart' />
                    </Link>
                    <Link to='/User' className='user-page-link'>
                        <FontAwesomeIcon icon={faUser} color="#fffff" className='user' />
                    </Link>

                </div>
                <div className='bottom-hamburgermenu' onClick={bottomBugermenyClicked}>
                    <div className='bottom-hamburger-child'></div>
                    <div className='bottom-hamburger-child'></div>
                    <div className='bottom-hamburger-child'></div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;