import './css/Contact.css'
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
//import sendEmail from '../JSFiles/Contact/SendContactEmail';

function Contact() {

    const maxLength = 550;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const formRef = useRef(null);

    function handleSubmit(e) {
        e.preventDefault();
        fetch('/api/contact', {
            method: 'POST',
            body: JSON.stringify({ firstName, lastName, email, subject, message }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                formRef.current.reset();
                alert('Thank you for contacting us!');
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Oops! Something went wrong.');
            });
    }

    const remaining = maxLength - message.length;

    return (
        <div className='contact-page'>
            <div className='contact-mainpage'>
                <div className='contact-message'>
                    Kontakt oss
                </div>
                <div className='contact-lower-message'>
                    <div>Hvis du ikke finner det du leter etter under&nbsp;<Link to="/" className='contact-lower-message-link'>Ofte stilte spørsmål</Link>.</div>
                    <div>Kan du kontakte oss igjennom dette skjemaet.</div>
                </div>
                <div className='contactForm'>
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <div className='contact-name-div'>
                            <label>
                                Fornavn
                                <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </label>
                            <label>
                                Etternavn
                                <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                            </label>
                        </div>
                        <div className='contact-grid-div'>
                            <label>
                                Email
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                            </label>
                            <label>
                                Emne
                                <input type="text" value={subject} onChange={e => setSubject(e.target.value)} />
                            </label>
                            <label>
                                Melding
                                <textarea value={message} onChange={e => setMessage(e.target.value)} className='contact-message-area' maxLength={maxLength}></textarea>
                                <div className='contact-message-area-count'>{remaining} ord igjen</div>
                            </label>
                        </div>
                        <button type="submit">Send inn</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;