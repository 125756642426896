
import './css/Favorite.css';

function Favorite() {
  return (
    <div className="Favorite">
      <header className="Favorite-header">
        <div>Favorite</div>
      </header>
    </div>
  );
}

export default Favorite;
