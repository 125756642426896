import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return(
        <div className='footer'>
            <div className='Footer-column1'>
                <h3>Snarveier</h3>
                <Link to='/User' className='footer-link'>
                    <i>Min side</i>
                </Link>
                <Link to='/' className='footer-link'>
                    <i>Ordre oversikt</i>
                </Link>
                <Link to='/' className='footer-link'>
                    <i>Gavekort</i>
                </Link>
            </div>
            <div className='Footer-column2'>
                <h3>Informasjon</h3>
                <Link to='/' className='footer-link'>
                    <i>Butikker</i>
                </Link>
                <Link to='/' className='footer-link'>
                    <i>Rabatter</i>
                </Link>
                <Link to='/' className='footer-link'>
                    <i>Om oss</i>
                </Link>
            </div>
            <div className='Footer-column3'>
                <h3>Kundestøtte</h3>
                <Link to='/' className='footer-link'>
                    <i>Ofte stilte spørsmål</i>
                </Link>
                <Link to='/kontakt-oss' className='footer-link'>
                    <i>Kontakt oss</i>
                </Link>
                <Link to='/' className='footer-link'>
                    <i>Ledige stillinger</i>
                </Link>
            </div>
        </div>
    );
}

export default Footer;