import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function StoreList({ stores, storePage, leftArrow, rightArrow }) {

  const [amountPerPage, setAmountPerPage] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setAmountPerPage(3);
      } else {
        setAmountPerPage(4);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const start = storePage * amountPerPage;
  const end = start + amountPerPage;

  const storeItems = stores.slice(start, end).map((store, index) => (
    <Link className='stores-link' to={store.link} key={"storelink: " + index}>
      <div className="stores" key={"store: "+index}>
        <img src={store.image} alt={store.name} />
      </div>
    </Link>

  ));

  return (
    <div className="stores-holder">
      <div className="store-arrow-left store-arrow" onClick={leftArrow}>
        <FontAwesomeIcon icon={faArrowLeft} color="#000000" className="arrow-left" />
      </div>
      <div className='stores-holder'>{storeItems}</div>
      <div className="store-arrow-right store-arrow" onClick={rightArrow}>
        <FontAwesomeIcon icon={faArrowRight} color="#000000" className="arrow-right" />
      </div>
    </div>
  );
}

export default StoreList;
