
import './css/Cart.css';

function Cart() {
  return (
    <div className="Cart">
      <header className="Cart-header">
        <div>Cart</div>
      </header>
    </div>
  );
}

export default Cart;
