
import './css/Home.css';
import Product from '../Images/product_image.jpeg';
import yourAdd from '../Images/your-ad-here.jpg'
import StoreList from '../JSFiles/Home/StoreList';
import yourStoreImage from '../Images/Store-logo-template.jpg'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Home() {

  const items = [
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
    {
      name: "Spris",
      image: Product,
      link: "/TestProduct"
    },
  ];

  const stores = [
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
    {
      name: "StoreLogo",
      image: yourStoreImage,
      link: "/TestStore"
    },
  ];

  const [storePage, setStorePage] = useState(0);
  const [amountPerStorePage, setAmountPerStorePage] = useState(4);

  useEffect(() => {
    const handleStorePages = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setAmountPerStorePage(3);
      } else {
        setAmountPerStorePage(4);
      }
    }
    handleStorePages();

    window.addEventListener('resize', handleStorePages);
    return () => {
      window.removeEventListener('resize', handleStorePages);
    };
  }, []);

  const leftArrow = () => {
    setStorePage((page) => 
    (page > 0 ? page - 1 : Math.ceil(stores.length/amountPerStorePage -1))
    );
  };

  const rightArrow = () => {
    setStorePage((page) =>
      page < Math.ceil(stores.length / amountPerStorePage) - 1 ? page + 1 : page = 0
    );
  };

  return (
    <div className="Home">

      <header className="Home-header">

        <div className='Home-column1'>

        </div>

        <div className='Home-main'>
          <div className='main-header'>
            <div className='home-main-image'>
              <img src={yourAdd} alt='Showcase'></img>
            </div>
          </div>
          <div className='blocker'></div>
          <div className='stores-topmessage'>Butikker vi sammarbeider med:</div>
          <StoreList stores={stores} storePage={storePage} leftArrow={leftArrow} rightArrow={rightArrow} />
          <div className='blocker'></div>
          <div className='item-holder'>
            {items.map((item, index) => {
              return (
                <Link className='item-link' to={item.link} key={"itemlink: "+index}>
                  <div className="item" key={"itemdiv: "+index}>
                    <img src={item.image} alt={item.name} />
                  </div>
                </Link>
              );
            })}
          </div>

        </div>

        <div className='Home-column3'>

        </div>

      </header>
    </div>
  );
}

export default Home;
