import React, { useState, useEffect } from "react";

function Profile() {
    const [loggedIn, setLoggedIn] = useState(false);
    

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (!isLoggedIn) {
      window.location.href = "/login";
    } else {
      setLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    setLoggedIn(false);
  };

    // Here is the profile site.
    if (loggedIn) {
        return (
            <div className="Profile-main">
                <div>
                    <button className="logOut" onClick={handleLogout}>Logg ut</button>
                </div>
            </div>
        );
    }
}

export default Profile;