import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './css/Login.css'
import axios from "axios";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userData, setUserData] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if(!email || !password) {
            setErrorMessage("Fyll inn alle feltene");
            return;
        }

        if (!emailExist) {
            setErrorMessage("Emailen finnes ikke");
            return;
        }
    }

    const emailExist = () => {
        let result = false;
        for (let i = 0; i < userData.length; i++) {
            if (userData[i].email === email) {
                result = true;
            }
        }

        return result;
    }

    useEffect(() => {
        axios.get('http://localhost:3001/api/get/userInformation').then((response) => {
            setUserData(response.data);
        });
    });

    return(
        <div className="loginPage">
            <h1>Log inn</h1>
            <form onSubmit={handleSubmit}>
                <div className="loginForm">
                    <label>Email:</label>
                    <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="loginForm">
                    <label>Passord:</label>
                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="login-button">Log inn</button>
            </form>
            <Link to="/Register" className="Register button">
                <div>Register</div>
            </Link>
        </div>
    );
}

export default Login;