// Register.js
import './css/Register.css'
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';

function Register() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [checkBox, setCheckBox] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [dbError, setDbError] = useState('');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [inputData, setInputData] = useState([]);

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    //const [daysMonth, setDaysMonth] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Validate form data
        if (
            !firstName ||
            !lastName ||
            !email ||
            !phoneNumber ||
            !address ||
            !postalCode ||
            !city ||
            !password ||
            !reEnterPassword ||
            !day ||
            !month ||
            !year ||
            !checkBox
        ) {
            setErrorMessage("All fields must be filled in");
            return;
        } else if (!isValidEmail(email)) {
            setErrorMessage("Invalid email");
            document.getElementById("email").style.borderColor = "red";
            return;
        } else if (!isPasswordsSame(password, reEnterPassword)) {
            setErrorMessage("Passwords do not match")
            document.getElementById("password").style.borderColor = "red";
            document.getElementById("retypePassword").style.borderColor = "red";
            return;
        } else if (emailAlreadyExist(email)) {
            setErrorMessage("Email is already in use");
            document.getElementById("email").style.borderColor = "red";
            return;
        } else if (!isValidDate(day, month, year)) {
            setErrorMessage("Invalid birthdate.");
            return;
        } else if (!checkBox) {
            setErrorMessage("You must agree to the terms and conditions");
            return;
        } else {
            const uniqueString = uniqueIdExist();
            console.log(uniqueString);
            const dateOfBirth = `${day}-${month}-${year}`;
            if (canConnectToDb) {
                axios.post("http://localhost:3001/api/register", {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                    phoneNumber: phoneNumber,
                    birthday: dateOfBirth,
                    adress: address,
                    postalCode: postalCode,
                    city: city,
                    terms: checkBox,
                    stringIdentifier: uniqueString,
                })
                .then(window.location.href = `/Registered?successfulregister=${true}&ID=${uniqueString}`);
    
            } else {
                scrollToTop();
                setDbError("Could not connect to server, please try again later.");
            }
        };
    }
    
    const isValidEmail = (email) => {
        // Regular expression for email validation
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    }

    const canConnectToDb = async () => {
        let connection = false;
        try {
            const response = await fetch('http://localhost:3001/api/get/userInformation');
            if (!response.ok) {
                connection = false;
                console.log("Could not connect to server");
            } else {
                connection = true;
            }

        } catch (error) {
            console.log("Could not connect to server");
            connection = false;
        }
    
        return connection;
    }

    const isPasswordsSame = (password, reEnterPassword) => {
        return (password === reEnterPassword)
    }

    useEffect(() => {
        if (canConnectToDb) {
            axios.get('http://localhost:3001/api/get/userInformation').then((response) => {
                setInputData(response.data);
            });
        } else {
            console.log("Could not connect")
        }
        
    }, []);

    const emailAlreadyExist = (email) => {
        let emailExist = false;
        for (let i = 0; i < inputData.length; i++) {
            if (inputData[i].email === email) {
                emailExist = true;
            }
        }
        return emailExist;
    }

    const years = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        years.push(i.toString());
    }

    const days = [];
    for (let i = 1; i <= 31; i++) days.push(i);

    const isValidDate = (day, month, year) => {
        if (year === "null" || month === "null" || day === "null") {
            return false; // invalid date input
        }

        const date = new Date(`${year}-${month}-${day}`);

        if (date.getMonth() + 1 !== parseInt(month, 10)) {
            return false; // invalid month
        }
        if (date.getFullYear() !== parseInt(year, 10)) {
            return false; // invalid year
        }
        if (date.getDate() !== parseInt(day, 10)) {
            return false; // invalid day
        }
        if (month === '02' && day === '29') { // check for February 29
            if (year % 4 !== 0 || (year % 100 === 0 && year % 400 !== 0)) {
                return false; // not a leap year
            }
        }
        return true; // valid date
    };

    const generateUniqueString = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let string = '';
        for (let i = 0; i < 10; i++) {
            string += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return string;
    };

    const uniqueIdExist = () => {
        let string = generateUniqueString();

        while (true) {
            let found = false;
            for (let i = 0; i < inputData.length; i++) {
                if (inputData[i].stringIdentifier === string) {
                    found = true;
                    string = generateUniqueString();
                    break;
                }
            }
            if (!found) {
                return string;
            }
        }
    }

    return (
        <div className='Register-page'>
            <h1>Registrering</h1>
            <div className='Register-div'>
                {dbError &&
                    <h1 className='dbErrorRegister'>{dbError}</h1>
                }
                <form onSubmit={handleSubmit} className='register-form'>
                    <div className='RegisterHalfInput'>
                        <div className='formDivRegister'>
                            <label htmlFor="firstName">Fornavn:</label>
                            <input type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className='formDivRegister'>
                            <label htmlFor="lastName">Etternavn:</label>
                            <input type="text" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor="email">Email:</label>
                        <input id='email' type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor="password">Passord:</label>
                        <input id='password' type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor="password">Skriv passord på nytt:</label>
                        <input id='retypePassword' type="password" name="password" value={reEnterPassword} onChange={(e) => setReEnterPassword(e.target.value)} />
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor="phoneNumber">Telefon nummer:</label>
                        <input type="text" name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor='dob'>Fødselsdato:</label>
                        <div className='dob'>
                            <select id='day' name='day' value={day} onChange={(e) => setDay(e.target.value)}>
                                <option value="null">Dag</option>
                                {days.map((day) => (
                                    <option key={"Day " + day} value={day}>{day}</option>
                                ))}
                            </select>
                            <select id='month' name='month' value={month} onChange={(e) => setMonth(e.target.value)}>
                                <option value="null">Måned</option>
                                <option value="1">Januar</option>
                                <option value="2">Februar</option>
                                <option value="3">Mars</option>
                                <option value="4">April</option>
                                <option value="5">Mai</option>
                                <option value="6">Juni</option>
                                <option value="7">Juli</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">Oktober</option>
                                <option value="11">November</option>
                                <option value="12">Desember</option>
                            </select>
                            <select id='Year' name='Year' value={year} onChange={(e) => setYear(e.target.value)}>
                                <option value="null">År</option>
                                {years.map((item) => (
                                    <option key={"year" + item} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='formDivRegister'>
                        <label htmlFor="address">Adresse:</label>
                        <input type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className='RegisterHalfInput'>
                        <div className='formDivRegister'>
                            <label htmlFor="postalCode">Postnummer:</label>
                            <input type="text" name="postalCode" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                        </div>
                        <div className='formDivRegister'>
                            <label htmlFor="city">By:</label>
                            <input type="text" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                    </div>
                    <div className='checkBoxAndTerms'>
                        <input type='checkbox' className='terms' checked={checkBox} onChange={(e) => setCheckBox(e.target.checked)} />
                        <label className='termsText' htmlFor='checkTermsAndCondition'>Ved å krysse av her godkjenner du at du har lest og godtatt våre <Link to="/terms">Terms & Conditions</Link></label>
                    </div>
                    <button type="submit" className='register-button'>Registrer</button>
                    {errorMessage &&
                        <div className='register-errormessage'>{errorMessage}</div>
                    }
                </form>
            </div>
        </div>
    );
}
export default Register;