import "./css/TestProduct.css";

function TestProduct() {
    return(
        <div>
            TestProduct
        </div>
    );
}

export default TestProduct;