export const BugermenyClicked = (event) => {
    const parentDiv = event.currentTarget;
    const childDivs = parentDiv.querySelectorAll('.hamburger-child');
    const menu = document.querySelector('.hamburger-slide');

    let clicked = false;

    if (!clicked) {
        childDivs[0].classList.toggle('rotatePos');
        childDivs[1].classList.toggle('hidden');
        childDivs[2].classList.toggle('rotateNeg');
        menu.classList.toggle("showHamburgerMenu");

        clicked = true;

    } else if (clicked) {
        childDivs[0].classList.toggle('rotateNeg');
        childDivs[1].classList.toggle('show');
        childDivs[2].classList.toggle('rotatePos');

        clicked = false;

    } else {
        console.log("Error: burgermenu error");
    }
};

export const bottomBugermenyClicked = (event) => {
    const parentDiv = event.currentTarget;
    const childDivs = parentDiv.querySelectorAll('.bottom-hamburger-child');
    const menu = document.querySelector('.bottom-hamburger-slide');

    let clicked = false;

    if (!clicked) {
        childDivs[0].classList.toggle('rotatePos');
        childDivs[1].classList.toggle('hidden');
        childDivs[2].classList.toggle('rotateNeg');
        menu.classList.toggle("showBottomHamburgerMenu");

        clicked = true;

    } else if (clicked) {
        childDivs[0].classList.toggle('rotateNeg');
        childDivs[1].classList.toggle('show');
        childDivs[2].classList.toggle('rotatePos');

        clicked = false;

    } else {
        console.log("Error: burgermenu error");
    }
};